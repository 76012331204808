<template>
    <div class="columns">
        <div class="column is-full is-two-thirds-desktop">
            <label class="label">{{ $t('Neighborhood') }}</label>
            <v-select
                id="quarter-location-select"
                v-model="quarterObject"
                :options="paginated"
                label="search_key"
                :filterable="true"
                :clearable="false"
                @search="query => search = query"
            >
                <template #list-footer>
                    <li
                        :key="-1"
                        class="pagination"
                    >
                        <button
                            type="button"
                            class="pagination-previous"
                            :disabled="!hasPrevPage"
                            @click="offset -= 10"
                        >{{ $t('Prev') }}
                        </button>
                        <button
                            type="button"
                            class="pagination-next"
                            :disabled="!hasNextPage"
                            @click="offset += 10"
                        >{{ $t('Next') }}
                        </button>
                    </li>
                </template>
            </v-select>
            <input
                name="bg_quarter_id"
                :value="quarterObject ? quarterObject.quarter_id : null"
                type="text"
                hidden
            >
        </div>
        <div class="column is-one-third">
            <div class="field">
                <label class="label">{{ $t('bl. No.') }}</label>
                <div class="control">
                    <input
                        name="bg_bl"
                        :value="blockNumber"
                        type="text"
                        class="input"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computedPagination} from '../Shared/vselect-pagination.js';

export default {
    name: "BgQuarterSelect",
    props: {
        // selected city
        selectedQuarterId: {
            required: false,
            default: null,
            type: Number
        },
        // array of BG quarters
        quarters: {
            required: true,
            type: Array
        },
        // eslint-disable-next-line vue/require-prop-types
        chosenBlockNumber: {
            required: false,
            default: null,
        }
    },
    data() {
        return {
            quarterObject: {
                quarter_id: null,
                city_id: null,
                search_key: null,
            },
            search: '',
            offset: 0,
            limit: 20,
            searchableQuarters: [],
            blockNumber: '',
        };
    },
    computed: {
        filtered() {
            return this.searchableQuarters.filter(quarter => {
                return quarter.search_key.toUpperCase().includes(this.search.toUpperCase());
            });
        },
        ...computedPagination
    },
    mounted() {
        this.blockNumber = this.chosenBlockNumber;
        this.findQuarters();
        if(this.selectedQuarterId) {
            this.quarterObject = this.quarters.find(element => element.quarter_id === this.selectedQuarterId);
        }

        // handler for events emitted from BgCitySelect component
        this.emitter.on('changedCityId', (id) => {
            // noinspection JSIncompatibleTypesComparison / it's not alwys null..
            if(this.quarterObject === null || this.quarterObject === undefined || this.quarterObject.city_id !== id) {
                this.quarterObject = {
                    quarter_id: null,
                    city_id: null,
                    search_key: null,
                };

                this.findQuarters(id);
            }
        });
    },
    methods: {
        findQuarters: function (id = null) {
            // try to find city
            if(id === null) {
                this.searchableQuarters = [];
                return;
            }

            // filter available quarters based on passed id
            this.searchableQuarters = this.quarters.filter( element => element.city_id === id);

            if(this.searchableQuarters.length === 1) {
                this.quarterObject = this.searchableQuarters[0];
            }
        },
    }
};
</script>
