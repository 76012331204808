export default class DhlPickupPoint {
    constructor({location, searchKey, name, place}) {
        this.location_id = location['ids'][0] != null ? location['ids'][0]['locationId'] : null;
        this.searchKey = searchKey;
        this.name = name;
        this.address = place['address']['streetAddress'] != null ? place['address']['streetAddress'] : null;
        this.postalCode = place['address']['postalCode'] != null ? place['address']['postalCode'] : null;
        this.city = place['address']['addressLocality'] != null ? place['address']['addressLocality'] : null;
    }
}
