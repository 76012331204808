<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Parcel machine') }}</label>
                <v-select
                    id="machine-location-select"
                    v-model="pickupPointObject"
                    :options="currentPickupPoints"
                    label="street"
                    @search="searchPickupPoints($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="posta_si_pickup_point_id"
                    :value="pickupPointObject ? pickupPointObject.unit_id : null"
                    type="text"
                    hidden
                >
                <input
                    name="posta_si_city_pickup_point"
                    :value="pickupPointObject ? pickupPointObject.city : null"
                    type="text"
                    hidden
                >
                <input
                    name="posta_si_post_number_pickup_point"
                    :value="pickupPointObject ? pickupPointObject.post_code : null"
                    type="text"
                    hidden
                >
                <input
                    name="posta_si_address_pickup_point"
                    :value="pickupPointObject ? pickupPointObject.street : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';

export default {
    name:  "SiPostaSlovenijePickupPointSelect",

    props: {
        selectedPickupPointId: {
            required: true,
            default: null,
            type: String
        },
        initialPickupPoints: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            pickupPointObject: {
                unit_id: null,
                search_key: null,
                city: null,
                post_code: null,
                street: null,
            },
            currentPickupPoints: [],
        };
    },
    watch: {
        pickupPointObject(newValue) {
            if (newValue === null) {
                this.searchPickupPoints('');
            }
        }
    },
    created() {
        this.currentPickupPoints = this.initialPickupPoints;
        if (this.selectedPickupPointId !== null) {
            this.findParcelMachine();
        }
    },
    methods: {
        findParcelMachine: function () {
            this.pickupPointObject = this.currentPickupPoints.
                find(element => element.unit_id === this.selectedPickupPointId);
        },
        searchPickupPoints: _.debounce(
            function (event) {
                axios.get('/posta-si/search-pickup-points', {
                    params: {
                        search_word: event
                    }
                }).then(response => {
                    this.currentPickupPoints = response.data;
                });
            }, 500)
    },
};
</script>
