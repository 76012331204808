<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Parcel machine') }}</label>
                <v-select
                    id="machine-location-select"
                    v-model="parcelMachine"
                    :options="currentParcelMachines"
                    label="search_key"
                    @search="searchParcelMachines($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="sameday_ro_parcel_locker_id"
                    :value="parcelMachine ? parcelMachine.locker_id : null"
                    type="text"
                    hidden
                >
                <input
                    name="sameday_ro_parcel_name"
                    :value="parcelMachine ? parcelMachine.name : null"
                    type="text"
                    hidden
                >
                <input
                    name="sameday_ro_parcel_city"
                    :value="parcelMachine ? parcelMachine.city : null"
                    type="text"
                    hidden
                >
                <input
                    name="sameday_ro_parcel_post_code"
                    :value="parcelMachine ? parcelMachine.post_code : null"
                    type="text"
                    hidden
                >
                <input
                    name="sameday_ro_parcel_address"
                    :value="parcelMachine ? parcelMachine.address : null"
                    type="text"
                    hidden
                >
                <input
                    name="sameday_ro_parcel_county"
                    :value="parcelMachine ? parcelMachine.county : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';

export default {
    name:  "SamedayParcelMachineSelect",

    props: {
        selectedParcelMachineId: {
            required: true,
            default: null,
            type: String
        },
        initialParcelMachines: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            parcelMachine: {
                locker_id: null,
                search_key: null,
                address: null,
                city: null,
                county: null,
                post_code: null,
                name: null,
            },
            currentParcelMachines: [],
        };
    },
    watch: {
        parcelMachine(newValue) {
            if (newValue === null) {
                this.searchParcelMachines('');
            }
        }
    },
    created() {
        this.currentParcelMachines = this.initialParcelMachines;
        if (this.selectedParcelMachineId !== null) {
            this.findParcelMachine();
        }
    },
    methods: {
        findParcelMachine: function () {
            this.parcelMachine = this.currentParcelMachines.
                find(element => element.locker_id === parseInt(this.selectedParcelMachineId));
        },
        searchParcelMachines: _.debounce(
            function (event) {
                axios.get('/sameday/search-parcel-machines', {
                    params: {
                        search_word: event
                    }
                }).then(response => {
                    this.currentParcelMachines = response.data;
                });
            }, 500)
    },
};
</script>
