export const computedPagination = {
    paginated() {
        return this.filtered.slice(this.offset, this.limit + this.offset);
    },
    hasNextPage() {
        const nextOffset = this.offset + 10;
        return Boolean(this.filtered.slice(nextOffset, this.limit + nextOffset).length);
    },
    hasPrevPage() {
        const prevOffset = this.offset - 10;
        return Boolean(this.filtered.slice(prevOffset, this.limit + prevOffset).length);
    }
};
