<template>
    <div class="columns">
        <div class="column is-two-thirds">
            <div class="field">
                <label class="label">{{ $t('ZIP / Postal code') }}</label>
                <input
                    v-model="inputValue"
                    name="fr_postal_code"
                    type="number"
                    required
                >
                <p
                    v-if="this.error"
                    class="help is-danger"
                >
                    {{ $t('This zip code is not allowed.') }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "FrZipCodeSelect",
    props: {
        // preselected zip code
        selectedZip: {
            required: false,
            default: null,
            type: String
        },
        // array blocked zip codes
        zipCodes: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            // this is city object
            cityObject: {
                id: null,
                name: null,
            },
            inputValue: '',
            offset: 0,
            limit: 20,
            error: false,
        };
    },

    watch: {
        inputValue() {
            this.checkZipCode();
        }
    },
    mounted() {
        this.inputValue = parseInt(this.selectedZip);
    },
    methods: {
        checkZipCode() {
            // check if entered zip code is not allowed
            if (this.inputValue === null) {
                return;
            }

            // check for match
            for (let zip in this.zipCodes) {
                if (zip === this.inputValue) {
                    this.error = true;
                    return;
                }
            }

            this.error = false;
        }
    },
};
</script>
