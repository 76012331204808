<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Office delivery') }}</label>
                <v-select
                    id="office-location-select"
                    v-model="locationObject"
                    :options="currentOffices"
                    :getOptionLabel="locationObject => locationObject.name ? locationObject.name + ',' + locationObject.address : ''"
                    @search="searchOffices($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="balik_post_office_name"
                    :value="locationObject ? locationObject.name : null"
                    type="text"
                    hidden
                >
                <input
                    name="balik_post_office_city"
                    :value="locationObject ? locationObject.district : null"
                    type="text"
                    hidden
                >
                <input
                    name="balik_post_office_postal_code"
                    :value="locationObject ? locationObject.post_code : null"
                    type="text"
                    hidden
                >
                <input
                    name="balik_post_office_address"
                    :value="locationObject ? locationObject.address : null"
                    type="text"
                    hidden
                >
                <input
                    name="balik_post_office_id"
                    :value="locationObject ? locationObject.post_code : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import _ from 'lodash';

export default {
    name:  "BalikPostOfficeSelect",

    props: {
        selectedPostOfficeId: {
            required: true,
            default: null,
            type: String
        },
        initialPostOffices: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            locationObject: {
                name: null,
                district: null,
                post_code: null,
                address: null,
            },
            currentOffices: [],
        };
    },
    watch: {
        locationObject(newValue) {
            if (newValue === null) {
                this.searchOffices('');
            }
        }
    },
    mounted() {
        this.currentOffices = this.initialPostOffices;
        if (this.selectedPostOfficeId !== '') {
            this.findOffice();
        }
    },
    methods: {
        findOffice: function () {
            this.locationObject = this.currentOffices.
                find(element => element.post_code === parseInt(this.selectedPostOfficeId));
        },
        searchOffices: _.debounce(
            function (event) {
                axios.get('/balik/search-post-offices', {
                    params: {
                        search_word: event
                    }
                }).then(response => {
                    this.currentOffices = response.data;
                });
            }, 500)

    },
};
</script>
