<template>
    <div class="columns">
        <div class="column is-one-third">
            <div class="field">
                <label class="label">{{ $t('Province') }}</label>
                <v-select
                    id="ro-province-select"
                    v-model="provinceObject"
                    :value="citiesFormatted.name"
                    label="name"
                    :options="provinces"
                    :clearable="false"
                    :searchable="true"
                    :no-drop="false"
                    autocomplete="true"
                >
                    <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            :required="!provinceObject"
                            v-bind="attributes"
                            v-on="events"
                        >
                    </template>
                </v-select>
            </div>
        </div>
        <div class="column is-one-third">
            <div class="field">
                <label class="label">{{ $t('City') }}</label>
                <v-select
                    id="ro-city-select"
                    v-model="cityObject"
                    :options="paginated"
                    label="name"
                    :filterable="true"
                    :clearable="false"
                    @search="query => this.search = query"
                >
                    <template #list-footer>
                        <li
                            :key="-1"
                            class="pagination"
                        >
                            <button
                                type="button"
                                class="pagination-previous"
                                :disabled="!hasPrevPage"
                                @click="offset -= 10"
                            >{{ $t('Prev') }}
                            </button>
                            <button
                                type="button"
                                class="pagination-next"
                                :disabled="!hasNextPage"
                                @click="offset += 10"
                            >{{ $t('Next') }}
                            </button>
                        </li>
                    </template>
                </v-select>
            </div>
            <input
                name="place_id"
                :value="cityObject ? cityObject.id : null"
                type="text"
                hidden
            >
            <input
                name="city"
                :value="cityObject ? cityObject.name : null"
                type="text"
                hidden
            >
            <input
                name="province"
                :value="provinceObject ? provinceObject.name : null"
                type="text"
                hidden
            >
            <input
                name="locality_id"
                :value="provinceObject ? provinceObject.id : null"
                type="text"
                hidden
            >
        </div>
    </div>
</template>

<script>
import {computedPagination} from "../Shared/vselect-pagination";

export default {
    name: "RoCountyLocalitySelect",
    props: {
        // selected county
        selectedCity: {
            required: true,
            type: String
        },
        // selected province
        selectedProvince: {
            required: true,
            type: String
        },
        // array of counties
        places: {
            required: true,
            type: Array
        },
        // array of localities
        provinces: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            // this is city object
            cityObject: {
                id: null,
                name: null,
                ro_county_id: null,
            },
            provinceObject: {
                id: null,
                name: null,
            },
            citiesFormatted: [],
            search: '',
            offset: 0,
            limit: 20,
        };
    },
    computed: {
        filtered() {
            this.formatCities();

            return this.citiesFormatted.filter(city => {
                return city.name.normalize().toUpperCase().includes(this.search.normalize().toUpperCase());
            });
        },
        ...computedPagination
    },
    watch: {
        provinceObject: function () {
            // reset
            if (this.cityObject === undefined || this.provinceObject.id !== this.cityObject.ro_county_id) {
                this.cityObject = {
                    id: null,
                    name: null,
                    ro_county_id: null,
                };
            }
            this.formatCities();
        },

        cityObject: function () {
            // reset
            if (this.cityObject === undefined || this.cityObject === null) {
                this.cityObject = {
                    id: null,
                    name: null,
                    ro_county_id: null,
                };
            }
        }
    },
    mounted() {
        if (this.selectedProvince.length !== 0) {
            this.findProvince();
        }

        this.formatCities();

        if (this.selectedCity.length !== 0 && this.provinceObject !== undefined) {
            this.findCity();
        }
    },
    methods: {
        findCity: function () {
            // try to find city
            let match = this.citiesFormatted.find(element => element.name === this.selectedCity);

            if (match !== undefined) {
                this.cityObject = match;
            }
        },
        findProvince: function () {
            // try to find county
            let match = this.provinces.find(element => element.name === this.selectedProvince);

            if (match !== undefined) {
                this.provinceObject = match;
            }
        },
        formatCities: function () {
            this.citiesFormatted = this.places.filter(element => element.ro_county_id === this.provinceObject.id);
        }
    }
};
</script>
