<template>
    <div class="columns is-multiline">
        <div class="column is-full">
            <div class="field">
                <label class="label">{{ $t('City') }}</label>
                <v-select
                    id="machine-city-select"
                    v-model="cityObject"
                    :options="paginatedCities"
                    label="search_key"
                    :filterable="true"
                    :clearable="false"
                    @search="query => searchCity = query"
                >
                    <li
                        slot="list-footer"
                        class="pagination"
                    >
                        <button
                            type="button"
                            :disabled="!hasPrevPage"
                            @click="offset -= 10"
                        >{{ $t('Prev') }}
                        </button>
                        <button
                            type="button"
                            :disabled="!hasNextPage"
                            @click="offset += 10"
                        >{{ $t('Next') }}
                        </button>
                    </li>
                </v-select>
                <p class="help">{{ $t('Only cities with office delivery locations are available.') }}</p>
            </div>
        </div>
        <div class="column is-full">
            <div class="field">
                <label class="label">{{ $t('Parcel machine location') }}</label>
                <v-select
                    id="machine-location-select"
                    v-model="locationObject"
                    :options="paginated"
                    label="search_key"
                    :filterable="true"
                    :clearable="false"
                    @search="query => search = query"
                >
                    <template #list-footer>
                        <li
                            :key="-1"
                            class="pagination"
                        >
                            <button
                                type="button"
                                class="pagination-previous"
                                :disabled="!hasPrevPage"
                                @click="offset -= 10"
                            >{{ $t('Prev') }}
                            </button>
                            <button
                                type="button"
                                class="pagination-next"
                                :disabled="!hasNextPage"
                                @click="offset += 10"
                            >{{ $t('Next') }}
                            </button>
                        </li>
                    </template>
                </v-select>
                <p class="help">{{ $t('Only parcel machine locations within selected city are available.') }}</p>
                <input
                    name="bg_address_id_machine"
                    :value="locationObject ? locationObject.office_code : null"
                    type="text"
                    hidden
                >
                <input
                    name="bg_city_id_machine"
                    :value="locationObject ? locationObject.city_id : null"
                    type="number"
                    hidden
                >
                <input
                    name="post_number_machine"
                    :value="cityObject ? cityObject.postal_code : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import {computedPagination} from "../Shared/vselect-pagination";

export default {
    name: "PaketomatSelect",
    props: {
        // selected Office
        selectedParcelId: {
            required: true,
            default: null,
            type: String
        },
        // selected city
        selectedCityId: {
            required: true,
            default: null,
            type: Number
        },
        // array of BG machines
        machines: {
            required: true,
            type: Array
        },
        // array of BG cities
        cities: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            // this is machine object
            locationObject: {
                office_code: null,
                search_key: null,
                city_id: null,
            },
            // this is city object
            cityObject: {
                city_id: null,
                search_key: null,
                postal_code: null,
            },
            searchCity: '',
            search: '',
            offset: 0,
            limit: 10,
        };
    },
    computed: {
        paginatedCities() {
            // only cities with office locations
            return this.cities.filter(city => {
                return city.search_key.toUpperCase().includes(this.searchCity.toUpperCase());
            });
        },
        filtered() {
            return this.machines.filter(machine => {
                return machine.city_id === this.cityObject.city_id
                    && machine.search_key.toUpperCase().includes(this.search.toUpperCase());
            });
        },
        ...computedPagination
    },
    watch: {
        'cityObject.city_id': function () {
            // null can accrue, if input is empty..
            if (this.locationObject.city_id !== this.cityObject.city_id) {
                this.locationObject = {
                    office_code: null,
                    search_key: null,
                    city_id: null,
                };
            }
        }
    },
    mounted() {
        if (this.selectedParcelId !== '') {
            this.findMachine();
        }

        if (this.selectedParcelId !== '' && this.selectedCityId !== 0) {
            this.findCity();
        }
    },
    methods: {
        findMachine: function () {
            // try to find machine
            let parcel = this.machines.find(element => {
                return element.office_code === this.selectedParcelId;
            });

            if (parcel !== undefined) {
                this.locationObject = parcel;
            }
        },
        findCity: function () {
            // try to find city
            let city = this.cities.find(element => element.city_id === this.locationObject.city_id);

            if (city !== undefined) {
                this.cityObject = city;
            }
        },
    },
};
</script>
