<template>
    <div class="columns">
        <div class="column">
            <div class="field">
                <label class="label">{{ $t('Province') }}</label>
                <v-select
                    id="province-select"
                    v-model="provinceValue"
                    :options="paginated"
                    label="name"
                    :filterable="true"
                    :clearable="false"
                    @search="query => this.search = query"
                >
                    <template #list-footer>
                        <li
                            :key="-1"
                            class="pagination"
                        >
                            <button
                                type="button"
                                class="pagination-previous"
                                :disabled="!hasPrevPage"
                                @click="offset -= 10"
                            >{{ $t('Prev') }}
                            </button>
                            <button
                                type="button"
                                class="pagination-next"
                                :disabled="!hasNextPage"
                                @click="offset += 10"
                            >{{ $t('Next') }}
                            </button>
                        </li>
                    </template>
                </v-select>
                <input
                    name="province"
                    :value="provinceCode ? provinceCode : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import {computedPagination} from "../Shared/vselect-pagination";

export default {
    name: "ProvinceSelect",
    props: {
        // selected city
        selectedProvince: {
            required: false,
            default: null,
            type: String
        },
        // array of GR cities
        provinces: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            // selected value by user
            provinceValue: '',
            provinceCode: '',
            search: '',
            offset: 0,
            limit: 20,
        };
    },
    computed: {
        filtered() {
            return Object.values(this.provinces).filter(key => key.toUpperCase().includes(this.search.toUpperCase()));
        },
        ...computedPagination
    },
    watch: {
        provinceValue() {
            if (this.provinceValue === null) {
                this.provinceCode = null;
                return;
            }

            // find province key by province name
            this.provinceCode = Object.keys(this.provinces).find(key => this.provinces[key] === this.provinceValue);
        }
    },
    mounted() {
        if (this.selectedProvince !== null) {
            this.findProvince();
        }
    },
    methods: {
        findProvince: function () {
            if (this.provinces.hasOwnProperty(this.selectedProvince)) {
                this.provinceValue = this.provinces[this.selectedProvince];
            }
        },
    }
};
</script>
