<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Pickup point') }}</label>
                <v-select
                    id="pickup-point-location-select"
                    v-model="pickupPointObject"
                    :options="currentPickupPoints"
                    label="searchKey"
                    @search="searchPickupPoints($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="nl_dhl_pickup_point_location_id"
                    :value="pickupPointObject ? pickupPointObject.location_id : null"
                    type="text"
                    hidden
                >
                <input
                    name="nl_dhl_pickup_point_city"
                    :value="pickupPointObject ? pickupPointObject.city : null"
                    type="text"
                    hidden
                >
                <input
                    name="nl_dhl_pickup_point_post_number"
                    :value="pickupPointObject ? pickupPointObject.postalCode : null"
                    type="text"
                    hidden
                >
                <input
                    name="nl_dhl_pickup_point_address"
                    :value="pickupPointObject ? pickupPointObject.address : null"
                    type="text"
                    hidden
                >
                <input
                    name="nl_dhl_pickup_point_name"
                    :value="pickupPointObject ? pickupPointObject.name : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import _ from 'lodash';
import DhlPickupPoint from "../../../responses/Forms/NL/Dhl/DhlPickupPoint";

export default {
    name:  "NlDhlPickupPointSelect",

    props: {
        selectedPickupPointId: {
            required: true,
            default: null,
            type: String
        },
    },
    data() {
        return {
            /** @type {DhlPickupPoint} */
            pickupPointObject: null,

            /** @type {DhlPickupPoint[]} */
            currentPickupPoints: [],

            // Word must be min of three letters.
            fallbackSearchWord: 'Amsterdam',
        };
    },
    mounted() {
        if (this.selectedPickupPointId !== '') {
            this.getPickupPoint();
        }

        // Preload some records on start.
        this.searchPickupPoints(this.fallbackSearchWord);
    },
    methods: {
        getPickupPoint: function () {
            axios.get(`/dhl/get-parcel/${this.selectedPickupPointId}`)
                .then(response => {
                    this.pickupPointObject = new DhlPickupPoint(response.data);
                });
        },

        searchPickupPoints: _.debounce(
            function (event) {
                // Perform search when at least three characters are entered.
                if (event.length < 3) {
                    return;
                }

                axios.get('/dhl/search-parcels', {
                    params: {
                        country_iso_code: 'NL',
                        search_word: event === '' ? this.fallbackSearchWord : event,
                    }
                }).then(response => {
                    this.currentPickupPoints = response.data.map(data => new DhlPickupPoint(data));
                });
            }, 500)
    },
};
</script>
