<template>
    <div class="columns">
        <div class="column is-two-thirds">
            <div class="field">
                <label class="label">{{ $t('City') }}</label>
                <v-select
                    id="gr-city-select"
                    v-model="cityObject"
                    :options="paginated"
                    label="name"
                    :filterable="true"
                    :clearable="false"
                    @search="query => this.search = query"
                >
                    <template #list-footer>
                        <li
                            :key="-1"
                            class="pagination"
                        >
                            <button
                                type="button"
                                class="pagination-previous"
                                :disabled="!hasPrevPage"
                                @click="offset -= 10"
                            >{{ $t('Prev') }}
                            </button>
                            <button
                                type="button"
                                class="pagination-next"
                                :disabled="!hasNextPage"
                                @click="offset += 10"
                            >{{ $t('Next') }}
                            </button>
                        </li>
                    </template>
                </v-select>
                <input
                    name="gr_city_id"
                    :value="cityObject ? cityObject.id : null"
                    type="text"
                    hidden
                >
                <input
                    name="city"
                    :value="cityObject ? cityObject.name : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import {computedPagination} from "../Shared/vselect-pagination";

export default {
    name: "GrCitySelect",
    props: {
        // selected city
        selectedCity: {
            required: false,
            default: null,
            type: String
        },
        // array of GR cities
        cities: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            // this is city object
            cityObject: {
                id: null,
                name: null,
            },
            search: '',
            offset: 0,
            limit: 20,
        };
    },
    computed: {
        filtered() {
            return this.cities.filter(city => {
                return city.name.normalize().toUpperCase().includes(this.search.normalize().toUpperCase());
            });
        },
        ...computedPagination
    },
    mounted() {
        if (this.selectedCity !== null) {
            this.findCity();
        }
    },
    methods: {
        findCity: function () {
            this.cityObject = this.cities.find(element => element.name === this.selectedCity);
        }
    },
};
</script>
