<template>
    <div class="column is-full">
        <label class="label">{{ $t('City') }}</label>
        <v-select
            id="home-city-select"
            v-model="cityObject"
            :options="paginated"
            label="search_key"
            :filterable="true"
            :clearable="false"
            @search="query => search = query"
        >
            <template #list-footer>
                <li
                    :key="-1"
                    class="pagination"
                >
                    <button
                        type="button"
                        class="pagination-previous"
                        :disabled="!hasPrevPage"
                        @click="offset -= 10"
                    >{{ $t('Prev') }}
                    </button>
                    <button
                        type="button"
                        class="pagination-next"
                        :disabled="!hasNextPage"
                        @click="offset += 10"
                    >{{ $t('Next') }}
                    </button>
                </li>
            </template>

        </v-select>
        <input
            name="bg_city_id_home"
            :value="cityObject ? cityObject.city_id : null"
            type="text"
            hidden
        >
        <input
            name="post_number_home"
            :value="cityObject ? cityObject.postal_code : null"
            type="text"
            hidden
        >
    </div>
</template>

<script>
import {computedPagination} from '../Shared/vselect-pagination.js';
export default {
    name: "CitySelect",
    props: {
        // selected city
        selectedCityId: {
            required: false,
            default: null,
            type: String
        },
        // array of BG cities
        cities: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            cityObject: {
                city_id: null,
                postal_code: null,
                search_key: null,
            },
            search: '',
            offset: 0,
            limit: 20,
        };
    },
    computed: {
        filtered() {
            return this.cities.filter(city => {
                return city.search_key.toUpperCase().includes(this.search.toUpperCase());
            });
        },
        ...computedPagination
    },
    watch: {
        cityObject: {
            // on selected city change, emit event which allows quarter component to change available selection
            handler(newValue, oldValue) {
                // skip same selection events
                if(newValue === undefined) {
                    return;
                }
                if (newValue === oldValue) {
                    return;
                }

                if (newValue.city_id !== null) {
                    this.emitter.emit('changedCityId', this.cityObject.city_id);
                    return;
                }
                this.emitter.emit('changedCityId', null);
            },
            deep: true
        }
    },
    mounted() {
        this.findCity();
    },
    methods: {
        findCity: function () {
            // try to find city
            let numericId = parseInt(this.selectedCityId);
            this.cityObject = this.cities.find(element => element.city_id === numericId);
        },
    }
};
</script>
