<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Pickup point') }}</label>
                <v-select
                    id="pickup-point-location-select"
                    v-model="pickupPointObject"
                    :options="formatedPickupPoints"
                    label="formatedPoint"
                    @search="searchPickupPoints($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="de_hermes_pickup_point_parcel_shop_id"
                    :value="pickupPointObject ? pickupPointObject.parcelShopNumber : null"
                    type="text"
                    hidden
                >
                <input
                    name="de_hermes_pickup_point_city"
                    :value="pickupPointObject ? pickupPointObject.city : null"
                    type="text"
                    hidden
                >
                <input
                    name="de_hermes_pickup_point_post_number"
                    :value="pickupPointObject ? pickupPointObject.zip : null"
                    type="text"
                    hidden
                >
                <input
                    name="de_hermes_pickup_point_street"
                    :value="pickupPointObject ? pickupPointObject.street : null"
                    type="text"
                    hidden
                >
                <input
                    name="de_hermes_pickup_point_house_number"
                    :value="pickupPointObject ? pickupPointObject.houseNumber : null"
                    type="text"
                    hidden
                >
                <input
                    name="de_hermes_pickup_point_name"
                    :value="pickupPointObject ? pickupPointObject.name : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import _ from 'lodash';

export default {
    name:  "DeHermesPickupPointSelect",

    props: {
        selectedPickupPointId: {
            required: true,
            default: null,
            type: String
        },
    },
    data() {
        return {
            pickupPointObject: {
                parcelShopNumber: null,
                city: null,
                zip: null,
                street: null,
                houseNumber: null,
            },
            currentPickupPoints: [],
            fallbackSearchWord: 'a',
        };
    },
    computed: {
        formatedPickupPoints() {
            return this.currentPickupPoints.map(point => ({
                ...point,
                formatedPoint: `${point.name}, ${point.street} ${point.houseNumber}, ${point.zip} ${point.city}`
            }));
        }
    },
    watch: {
        pickupPointObject(newValue) {
            if (newValue === null) {
                this.searchPickupPoints('a');
            }
        }
    },
    created() {
        if (this.selectedPickupPointId !== '') {
            this.getPickupPoint();
        }

    },
    methods: {
        findPickupPoint: function () {
            this.pickupPointObject = this.formatedPickupPoints.
                find(element => element.parcelShopNumber === this.selectedPickupPointId);
        },
        getPickupPoint: function () {
            axios.get('/de-hermes/get-pickup-point/', {
                params: {
                    id: this.selectedPickupPointId,
                }
            }).then(response => {
                this.currentPickupPoints = response.data;
                this.findPickupPoint();
            });
        },
        searchPickupPoints: _.debounce(
            function (event) {
                axios.get('/de-hermes/search-pickup-points/', {
                    params: {
                        search_word: event === '' ? this.fallbackSearchWord : event,
                    }
                }).then(response => {
                    this.currentPickupPoints = response.data;
                });
            }, 500)

    },
};
</script>
